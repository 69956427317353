import { render, staticRenderFns } from "./SearchResults.vue?vue&type=template&id=ed0e4d08&scoped=true&"
import script from "./SearchResults.vue?vue&type=script&lang=js&"
export * from "./SearchResults.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed0e4d08",
  null
  
)

export default component.exports