<template>
	<div class="container">
		<h1 class="title">Select the repair</h1>
		<div class="card-table">
			<repair-search-results
					v-for="(order, i) in orders"
					:key="i"
					:order="order"
			></repair-search-results>
		</div>
	</div>
</template>

<script>
	import {mapGetters} from 'vuex';

	export default {
		name: "SearchResults",

		computed: {
			...mapGetters(['device', 'order', 'orders'])
		},

		mounted() {
			if (!this.orders.length) {
				this.$router.push({name: `repairSearch`});
			}
		}
	}
</script>

<style scoped>

</style>